import { useEffect, useRef, useState } from 'react';
import './App.scss';

import { ReactComponent as Image } from './goti.svg'
import { ReactComponent as BlueImage } from './blue-g.svg'

import { ReactComponent as Path } from './path.svg'
import { ReactComponent as Path1 } from './blue-p.svg'


function App() {
  const myDivRef = useRef(null);
  const [isEndInView, setIsEndInView] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isEndOfDivInView = () => {
    if (myDivRef.current) {
      const divRect = myDivRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const divEnd = divRect.top + divRect.height;
      return divEnd <= windowHeight;
    }
    return false;
  };


  const handleScroll = () => {
    setIsEndInView(isEndOfDivInView());
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <header>
        <div>
          <h1>Blue Balls</h1>
          <div className='social-links'>
            <a href="#"><img src="/images/x.svg" alt="twitter" /></a>
            <a href="#"><img src="/images/telegram.svg" alt="telegram" /></a>
            <a href="#"><img src="/images/dex.svg" alt="dex" /></a>
            <a href="#"><img src="/images/uni.svg" alt="uniswap" /></a>
          </div>
        </div>
        <div>
          <p>
            Contract Address: 0x000...000<br />
            0% Sell Tax
          </p>
        </div>
      </header>

      <main>
        <div className='container'>
          <div className='is-flex is-flex-direction-column is-justify-content-center is is-align-item-center	'>
            <video className='desktop-video' src="/video/desktop-video.mp4" autoPlay loop muted></video>
            <img className='mobile-img' src="/images/mobile.png" alt="blueballs" />
          </div>
        </div>
      </main>
      {/* <footer className='section'>
        <div className='container'>
          <div className='footer-box-container footer-box p-4'>
            <div className='level p-6'>
              <div class="footer_content level-left">
                <div className='level-item is-flex-direction-column is-align-items-flex-start nut-box'>
                  <h5 className='mb-5 is-uppercase'>
                    Get Your $NUT or Get Nutted ON
                  </h5>
                  <ul className='social-media columns has-text-left'>
                    <li className='column'>
                      <a href="#">
                        <img src='/images/1.png' />
                      </a>
                    </li>
                    <li className='column'>
                      <a href="#">
                        <img src='/images/2.png' />
                      </a>
                    </li>
                    <li className='column'>
                      <a href="#">
                        <img src='/images/3.png' />
                      </a>
                    </li>
                    <li className='column'>
                      <a href="#">
                        <img src='/images/4.svg' />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='footer_content_2 level-right is-align-self-flex-start'>
                <ul>
                  <li className='mb-3 has-text-left'>
                    <a href="#">Tokenomics</a>
                  </li>
                  <li className='mb-3 has-text-left'>
                    <a href="#">Roadmap</a>
                  </li>
                  <li className='mb-3 has-text-left'>
                    <a href="#">Buy $NUT</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
    </div>
  );
}

export default App;
